<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.jobId ? 'View/Edit' : 'Create'} Job`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Customer" :disabled="editedJob.property_id" :invalid-feedback="getValidationMessage('customer_id')" :state="!validationErrors.customer_id">
                <customer-search @delete="editedJob.customer_id = null" @selected="onCustomerSelected" :params="{ type: 'customer' }" show-delete v-model="editedJob.customer_id" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Property" :disabled="editedJob.customer_id" :invalid-feedback="getValidationMessage('property_id')" :state="!validationErrors.property_id">
                <property-search @delete="editedJob.property_id = null" show-delete v-model="editedJob.property_id" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p class="text-muted"><small>Please specify only one: a customer, or a property; to book a cleaning job against.</small></p>
            </b-col>
          </b-row>
          <b-row class="border-top border-bottom my-2 pt-4 pb-2" v-if="editedJob.customer_id">
            <b-col cols="12">
              <p class="h5 mb-4 font-weight-bold">Address Information</p>
              <b-form-group label="Address Line 1" :invalid-feedback="getValidationMessage('address.address_line_1')" :state="validationErrors.address && validationErrors.address.address_line_1">
                <b-form-input type="text" v-model="editedJob.address.address_line_1"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Address Line 2" :invalid-feedback="getValidationMessage('address.address_line_2')" :state="validationErrors.address && validationErrors.address.address_line_2">
                <b-form-input type="text" v-model="editedJob.address.address_line_2"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="City" :invalid-feedback="getValidationMessage('address.city')" :state="validationErrors.address && validationErrors.address.city">
                <b-form-input type="text" v-model="editedJob.address.city"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="County" :invalid-feedback="getValidationMessage('address.county')" :state="validationErrors.address && validationErrors.address.county">
                <b-form-input type="text" v-model="editedJob.address.county"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Postcode" :invalid-feedback="getValidationMessage('.address.postcode')" :state="validationErrors.address && validationErrors.address.postcode">
                <b-form-input type="text" v-model="editedJob.address.postcode"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Date" :invalid-feedback="getValidationMessage('started_at')" :state="!validationErrors.started_at">
                <b-form-datepicker v-model="editedJob.started_at" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Preferred Weekday" :invalid-feedback="getValidationMessage('preferred_weekday')" :state="!validationErrors.preferred_weekday">
                <b-form-select :options="[
                  { text: 'Sunday', value: 0 },
                  { text: 'Monday', value: 1 },
                  { text: 'Tuesday', value: 2 },
                  { text: 'Wednesday', value: 3 },
                  { text: 'Thursday', value: 4 },
                  { text: 'Friday', value: 5 },
                  { text: 'Saturday', value: 6 },
                ]" v-model="editedJob.preferred_weekday" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Preferred Time" :invalid-feedback="getValidationMessage('preferred_time')" :state="!validationErrors.preferred_time">
                <b-form-timepicker minutes-step="15" v-model="editedJob.preferred_time" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Duration" :invalid-feedback="getValidationMessage('duration_min')" :state="!validationErrors.duration_min">
                <b-form-timepicker minutes-step="15" v-model="editedJob.duration_min" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Repeat Job (Days)" :invalid-feedback="getValidationMessage('interval_days')" :state="!validationErrors.interval_days">
                <b-form-input type="number" min="0" max="28" v-model.number="editedJob.interval_days" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveJob" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.jobId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import CustomerSearch from '../../components/CustomerSearch.vue'
import PropertySearch from '../../components/PropertySearch.vue'
import handleErrors from '../../mixins/handleErrors'

export default {
  components: { CustomerSearch, PropertySearch },
  emits: ['hidden'],
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedJob: {
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        customer_id: null,
        property_id: null,
        started_at: new Date(),
        preferred_weekday: new Date().getDay(),
        preferred_time: '09:00:00',
        duration_min: '00:30:00',
        interval_days: 0
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    job: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.jobId && this.job) {
      this.loading = true;

      try {
        const job = await this.fetchSingle(this.$route.params.jobId);
        const duration = moment.duration(job?.duration_min ?? 0, 'minutes')
        this.editedJob = {
          ...job,
          address: {
            address_line_1: '',
            address_line_2: '',
            city: '',
            county: '',
            postcode: '',
            ...job.address,
          },
          duration_min: `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load job. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('jobs', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedJob = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveJob() {
      this.saving = true;
      this.updateOrCreate({
        ...this.editedJob,
        type: this.editedJob.interval_days === 0 ? 'single': 'recurring',
        duration_min: moment.duration(this.editedJob.duration_min).asMinutes(),
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    onCustomerSelected(selected) {
      this.editedJob.address = {
          address_line_1: selected.address?.address_line_1 ?? '',
          address_line_2: selected.address?.address_line_2 ?? '',
          city: selected.address?.city ?? '',
          county: selected.address?.county ?? '',
          postcode: selected.address?.postcode ?? ''
      }
    }
  },
  watch: {
    job(value) {
      if (value === undefined) {
        return
      }

      const duration = moment.duration(value?.duration_min ?? 0, 'minutes')
      this.editedJob = {
        ...value,
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
          ...value.address,
        },
        duration_min: `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
      };
    }
  }
}
</script>

<style>

</style>
