<template>
  <resource-search @delete="() => this.$emit('delete')" @selected="(a) => this.$emit('selected', a)" :disabled="disabled" @input="id => this.$emit('input', id)" @search="text => this.$emit('search', text)" :fetch-params="params" module="customers" :show-delete="showDelete" :value="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0"><span class="font-weight-bold" v-if="result.company">{{ result.company }}</span> <span :class="result.company ? 'font-weight-light' : 'font-weight-bold'">{{ result.name }}</span></p>
      <p class="text-muted mb-0" v-if="showEmail"><small>{{ result.email }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0"><span class="font-weight-bold" v-if="selected.company">{{ selected.company }}</span> <span :class="selected.company ? 'font-weight-light' : 'font-weight-bold'">{{ selected.name }}</span></p>
      <p class="text-muted mb-0" v-if="showEmail"><small>{{ selected.email }}</small></p>
    </template>

    <template #placeholder>Search for {{ params.type || 'customer' }}s...</template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    params: { type: Object, required: false, default: () => {} },
    showEmail: { type: Boolean, required: false, default: true },
    showDelete: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'customer' },
    disabled: Boolean,
    value: Number,
  }
}
</script>

<style>

</style>
